
<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <!--<a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
              <sdHeading as="h5" style="font-weight: 500">
              <h3>Date Filter</h3>
                 <a-date-picker
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                <a-date-picker
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange1"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col>
        </a-row>-->
        <a-row :gutter="25">
          <a-col>
            <BasicFormWrapper>
            <div class="setting-card-title">
              <sdHeading as="h5" style="font-weight: 500">
              <h3>Date Filter</h3>
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row>
            <a-col>
              <a-form-item name="selectrange1">
                <a-date-picker
                  style="padding-left: 25px !important"
                  @change="DateSelect1"
                  :defaultValue="selectrange1"
                  placeholder="Input StartDate"
                />
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item name="selectrange2">
                <a-date-picker
                  @change="DateSelect2"
                  :defaultValue="selectrange2"
                  placeholder="Input EndDate"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row>
            <a-col>
                <a-button 
                style="margin: 0 20% !important"
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
                Submit
              </a-button>
              </a-col>
              </a-row>
            </a-form>
            </sdHeading>
            </div>
          </BasicFormWrapper>
          </a-col>
         
          </a-row>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <sdFeatherIcons type="x-circle" size="25" style="color:#fff !important;" />
    </div>
  </div>

</template>
<script>
import { Main, TableWrapper } from "/src/view/styled";
import { computed, ref, defineComponent, onMounted ,reactive} from "vue";
import { useRoute, useRouter } from "vue-router";
import { message, Notification } from "ant-design-vue";
import { useStore } from "vuex";
import { UserTableStyleWrapper, Action, ProductCard } from "/src/view/dashboard/style";
import * as FontAwesomeIcon from "@fortawesome/free-solid-svg-icons";
  export default defineComponent({
  name: "Modal",
  components: {
    Main,
    TableWrapper,
    UserTableStyleWrapper,
    ProductCard,
    FontAwesomeIcon,
    Action,
  },
  setup(props) {
    const selected = ref(props.id);
    let ids = selected;
    let orgdata = ref("");
    let count = ref("");
    let client_name = ref("");
    const param = useRoute();
    let showModal= ref(false);
    const router = useRouter();
    const { state, dispatch } = useStore();
    var selectrange = ref(null);
    var selectrange1 = ref(null);
    var selectrange2 = ref(null);
    var loading = ref(true);
    onMounted(() => {
   
    });
    const formState = reactive({
      date1: "",
      date2: "",
      layout: "vertical",
      
    });
    const handleOk = (values) => {
      xerosync();
    };
   const DateSelect1 = (date, dateString) => {
      selectrange1.value = dateString;
      validateDateRange();
    };
    const DateSelect2 = (date, dateString) => {
      selectrange2.value = dateString;
      validateDateRange();
    };
    const validateDateRange = () => {
    if (selectrange1.value && selectrange2.value) {
      const startDate = new Date(selectrange1.value);
      const endDate = new Date(selectrange2.value);
      const oneYearLater = new Date(startDate);
      oneYearLater.setFullYear(startDate.getFullYear() + 1);

      if (endDate > oneYearLater) {
        alert("The selected date range exceeds one year. Please select a valid range.");
        // Reset the invalid range (optional)
        selectrange2.value = null;
      }
    }
  };
    const xerosync = () => {
      
      //alert(selectrange1.value + selectrange2.value)
      //var range = selectrange.value.toString().split(",");
      //let startdate = new Date(range[0]);
      //let enddate = new Date(range[1]);
      let startdate =selectrange1.value.toString();
      let enddate =selectrange2.value.toString();
      //alert(startdate +" "+enddate);
      //startdate = startdate.toLocaleDateString("en-CA");
      //enddate = enddate.toLocaleDateString("en-CA");
      //alert(startdate +" "+enddate);
      var output = localStorage.getItem("output");
      //alert(output);
      var rangeobj = [];
      rangeobj.push(startdate);
      rangeobj.push(enddate);
      if(output=='Resync'){
      dispatch("getsync", rangeobj).then(
        (response) => {
          //ids.value = localStorage.getItem("org");
          //router.push("/overview");
          //location.reload(true);
          Notification["success"]({
              message: "Parent date Range has been Updated",
          });
          location.reload(true);
        },
        (error) => {
        Notification["error"]({
              message: "Error!",
              description: error.data.msg
            });

        });
      }
      else {
        dispatch("reposync", rangeobj).then(
        (response) => {
          Notification["success"]({
              message: "Child date Range has been Updated",
          });
          location.reload(true);
        },
        (error) => {
              router.push("/overview");
              Notification["error"]({
              message: "Error!",
              description: error.data.msg
            });

        }
      );
      }
    };

    return {
      ids,
      orgdata,
      client_name,
      showModal,
      count,
      xerosync,
      handleOk,
      formState,
      selectrange,
      selectrange1,
      selectrange2,
      DateSelect1,
      DateSelect2,
      validateDateRange,
      loading,
    };
  },
});

</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 250px;
  width: 500px;
  margin-top: 10%;
  padding: 40px 0;
  border-radius: 20px;
}
.close {
  margin: 10% 0 0 8px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #5F63F2;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}
</style>