<template>
  <div>
    <sdPageHeader title=" " />
    <Main>
      <div class="thank-you-container">
      <h1 style="font-size:30px; font-weight: 400;">Thank you for subscribing</h1>
        <div class="plan-details">
          <h3>Plan Details</h3>
          <p>You purchased the {{plan}} on {{startDate}}</p>
          <p>You will be charged {{currency}} {{amount}}/mo</p>
          <p>You Can Connect {{connectionLimit}} Organizations</p>
          <p>Current Period will end on {{currentEnd}}</p>
          <p>Your subscription will end on {{endDate}}</p>
          <small>
            This plan includes core features, customization options, and user support tailored to your needs. Specific benefits vary based on the selected plan.
          </small>
        </div>
        <!--<div class="additional-details">
          <p><strong>Account Details:</strong> ABCO1023456789</p>
          <p><strong>Contact Details:</strong> 123 Baker Street, London, W1U 6RZ, United Kingdom</p>
          <p><strong>Payment Method:</strong> Card</p>
        </div>-->
        <div class="footer-link">
          <a :href="link" target="_blank">For changing plan please visit <span>Xero</span></a>
        </div>
      </div>
    </Main>
  </div>
</template>

<script>
import { Main, TableWrapper } from "../styled";
import { computed, ref, defineComponent, onMounted,reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { UserTableStyleWrapper, Action, ProductCard } from "./style";
import * as FontAwesomeIcon from "@fortawesome/free-solid-svg-icons";
import { message, Notification } from 'ant-design-vue';

export default defineComponent({
  name: "thankdashboard",
  components: {
    Main,
    TableWrapper,
    UserTableStyleWrapper,
    ProductCard,
    FontAwesomeIcon,
    Action,
    
  },
 
  setup() {
    let empty = ref(true);
    const param = useRoute();
    const router = useRouter();
    const { state, dispatch } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    var plan = ref();
    var amount = ref();
    var currency = ref();
    var startDate = ref();
    var endDate = ref();
    var connectionLimit = ref();
    var currentEnd = ref();
    var link = ref();
    onMounted(() => {
    getdata();
    });
    const getdata = () => {
      dispatch("xeroSubscribedData").then(
        (response) => {
            plan.value = response.data[0].plan_name;
            amount.value = response.data[0].amount;
            currency.value = response.data[0].currency;
            startDate.value = response.data[0].subscription_startdate;
            endDate.value = response.data[0].subscription_enddate;
            connectionLimit.value = response.data[0].connection_limit;
            currentEnd.value = response.data[0].current_period_end;
            link.value = response.data[0].link;
          Notification["success"]({
            message: "Succsess!",
            description: "Subscription data retrieved.",
            });
        },
        (error) => {
        Notification["error"]({
          message: "API Error!",
          description: "Error Getting Data",
        });
      }
      );
    };
    return {
    empty,
    plan,
    amount,
    currency,
    startDate,
    endDate,
    connectionLimit,
    currentEnd,
    link,
    };
  },
});
</script>
<style scoped>
.thank-you-container {
  background-color: #f9f9f9;
  padding: 40px;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.plan-details {
  background-color: #e8ebff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.plan-details h3 {
  margin-bottom: 10px;
}

.plan-details p {
  margin: 5px 0;
}

.plan-details small {
  display: block;
  margin-top: 10px;
  font-size: 0.85em;
  color: #666;
}

.additional-details {
  margin-bottom: 20px;
  text-align: left;
}
.ant-page-header-heading-left {
  margin-left: 33% !important;
  text-align: center !important;
  display: block !important;  
}
.ant-page-header-heading-title {
  margin-left: 33% !important;
  text-align: center !important;
  display: block !important;
}
.additional-details p {
  margin: 5px 0;
}

.footer-link a {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  color: #0070f3;
  font-weight: bold;
}

.footer-link span {
  text-decoration: underline;
}
</style>