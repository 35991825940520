<template>
  <sdCards title="AP/AR" size="default" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
        <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_dupinvoices">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Duplicate Invoices
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Contain Invoice having same Supplier/Amount,Date,Invoice Number.</li>
                          <li>Diffrent Supplier same Amount.</li>
                          <li>Same Invoice Number/ Different Supplier.</li>
                          <li>Check Suspected Duplicate Analysis Report.</li>
                          <li>Expenses Booked through Spend Money also PI.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['duplicate_invoices'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["duplicate_invoices"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_pay">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Unallocated Payments
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Identify Payments(SPEND-OVERPAYMENTS) made to suppliers which are not allocated against any invoice.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['unallocated_Payments'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['unallocated_Payments'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="partial_pay">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Partial Paid Bills
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Identify Payment Made to Suppliers which are not allocated fully.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['partial_payments'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['partial_payments'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="receipts_allocation">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                      Partial Paid Invoice
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Identify Receipts from Customers which are not allocated fully.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['receipts_allocation'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['receipts_allocation'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_PIdraft">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        PI Invoices in Drafts
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Lists Purchase Invoices in Drafts.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['pi_invoices_in_drafts'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['pi_invoices_in_drafts']}}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_debit">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Suppliers with Debit Balances
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>List all Suppliers in Debit Balances within report review period.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['suppliers_with_debit_balances'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{data['suppliers_with_debit_balances'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_credit">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Customers with Credit Balances
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>List all Customers in Credit Balances within report review period.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['customers_with_credit_balances'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{data['customers_with_credit_balances'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_supplierinvoices">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        SI > 90 Days
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Sales Invoices outstanding for more than 90 Days from Report Sync-> End Date.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['pi_90_days'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['pi_90_days'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_invoicematch">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Invoices Matched for Later Dates
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Supplier Invoice / Customer Invoice are paid But Invoice is Paid before Invoice Date.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['wrong_mismatch'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['wrong_mismatch'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_receipt">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Unallocated Receipts
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Identify Receipts(RECIEVED-OVERPAYMENTS) made to Customers which are not allocated against any invoice.</li>
                        </ul>
                      </span>
                      </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['unallocated_receipts'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['unallocated_receipts'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_SIdraft">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        SI in Drafts
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Lists Sale Invoices in Drafts.</li>
                        </ul>
                      </span>
                      </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['si_in_drafts'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['si_in_drafts'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_dabtorinvoices">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        PI > 90 days
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Purchase Invoices outstanding for more than 90 Days from Report Sync-> End Date.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['si_90_days'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['si_90_days'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_payment_expensed">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Payments expensed out but invoices are outstanding
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Bank Transaction is Spent out But Invoices with same amount is outstanding.</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['payment_expensed'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['payment_expensed'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <!--<li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_foreign">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Foreign Currency Invoices
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Contain Invoice/Bank Transaction having Foreign Currencies</li>
                          
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['foreign_currencies'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["foreign_currencies"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_allocation">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Allocated Invoices
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Invoices Allocated against single / Multiple Payments</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['invoice_allocation'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["invoice_allocation"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_mode">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Payment Mode of Prevoiusly Paid Invoices
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Report showing payment mode of previously paid invoices for current outstanding invoices</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['xero_mode'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["xero_mode"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>-->
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
</template>

<script>
import { computed, ref, defineComponent, onMounted } from "vue";
import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../../../style";

export default defineComponent({
  name: "APAR1",
  props: ["data"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
    return {};
  },
  methods: {},
});
</script>
