<template>
  <div v-if="loading">
    <sdCards headless>
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
  </div>
  <div v-else>
    <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 0px">
            {{ orgdata.client_name }} - VAT Return Checklists
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync:
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
        </div>
        <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
              <sdHeading as="h5" style="font-weight: 500">
                Current Report Period&nbsp;
                <sdFeatherIcons type="calendar" size="16" />&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <!--v-if="orgdata.lastsync == 'not_synced' || orgdata.syncdate1== ' - '"--><br />
                <a-range-picker
                  style=" width: 300px !important; padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <!--v-if="orgdata.lastsync == 'not_synced' || orgdata.syncdate1== ' - '"-->
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Change Period
                </a-button>
              </sdHeading>
            </div>
          </a-col>
        </a-row>
      </template>
      <template v-slot:buttons>
        <div class="page-header-actions" style="">
          <!--<a-button
            class="btn-signin"
            size="small"
            @click="exportToCSV()"
          >
            <span
              style="
                display: inline-flex !important;
                align-items: center !important;
              "
            >
              <sdFeatherIcons type="download" size="14" /> &nbsp;
              Export</span
            >
          </a-button>-->
          <a-button
            class="btn-signin"
            size="small"
            @click="exportChecklist()"
          >
            <span
              style="
                display: inline-flex !important;
                align-items: center !important;
              "
            >
              <sdFeatherIcons type="download" size="14" /> &nbsp;
              Export </span
            >
          </a-button>
          <div v-if="refresh_btn">
            <a-button
              id="disabled"
              class="ant-btn ant-btn-primary ant-btn-sm"
              size="small"
              disabled
              @click="refresh()"
            >
              <span
                style="
                  display: inline-flex !important;
                  align-items: center !important;
                "
              >
                <sdFeatherIcons type="loader" size="14" /> &nbsp;
                Get Counts</span
              >
            </a-button>
          </div>
          <div v-else>
            <a-button
              id="disabled"
              class="ant-btn ant-btn-primary ant-btn-sm"
              size="small"
              @click="refresh()"
            >
              <span
                style="
                  display: inline-flex !important;
                  align-items: center !important;
                "
              >
                <!-- <sdFeatherIcons type="loader" size="14" /> &nbsp; -->
                Get Counts</span
              >
            </a-button>
          </div>
          
        </div>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
          <br />
          <br />
          &nbsp;
        </span>
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="25">
        <a-col :xxl="8" :md="12" :sm="12" :xs="24">
          <sdCards title="Details" style="height: 95% !important">
            <div class="notification">
              <ActivityContents>
              <ul class="activity-list">
              <li class="activity-list__single">
                <div class="activity-content">
                  <div class="activity-info">
                    <p>
                      <span class="tooltip1">
                        <h4 style="margin-bottom: 0px !important" >
                          VAT Scheme: {{ orgdata.SalesTaxBasis }}
                        </h4>
                      </span>
                    </p>
                  </div>
                </div>
              </li>
              <li class="activity-list__single">
                <div class="activity-content">
                  <div class="activity-info">
                    <p>
                      <span class="tooltip1">
                        <h4 style="margin-bottom: 0px !important" >
                          Reporting Cycle: {{ orgdata.SalesTaxPeriod }}
                        </h4>
                      </span>
                    </p>
                  </div>
                </div>
              </li>
              <li class="activity-list__single">
                <div class="activity-content">
                  <div class="activity-info">
                    <p>
                      <span class="tooltip1">
                        <h4 style="margin-bottom: 0px !important" >
                          Base Currency: {{ orgdata.base_currency }}
                        </h4>
                      </span>
                    </p>
                  </div>
                </div>
              </li>
              <li class="activity-list__single">
                <div class="activity-content">
                  <div class="activity-info">
                    <p>
                      <span class="tooltip1">
                        <h4 style="margin-bottom: 0px !important" >
                          <a target="_blank" :href="orgdata.finacial_setting"> <sdFeatherIcons type="settings" size="14" /> Change Setting</a>
                        </h4>
                      </span>
                    </p>
                  </div>
                </div>
              </li>
              </ul>
              </ActivityContents>
            </div>
          </sdCards>
        </a-col>
        
        <a-col :xxl="8" :md="12" :sm="12" :xs="24">
          <BalanceSheet1 :data="count" />
        </a-col>
        <a-col :xxl="8" :md="12" :sm="12" :xs="24">
          <Nominal1 :data="count" />
        </a-col>
        <a-col :xxl="8" :md="12" :sm="12" :xs="24">
          <APAR1 :data="count" />
        </a-col>
        
      </a-row>
    </Main>
  </div>
  
</template>

<script>
import { Main } from "../../styled";

import { computed, ref, defineComponent, onMounted } from "vue";
import { SentEmailWrapper, ActivityContents, ProductCard } from "./style";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import BalanceSheet1 from "./categories/balance/Balance_Sheet1.vue";
import Nominal1 from "./categories/nominal/Nominal1.vue";
import APAR1 from "./categories/ap/AP_AR1.vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { message, Notification } from 'ant-design-vue'; 

export default defineComponent({
  name: "overview",
  components: {
    Main,
    ProductCard,
    ActivityContents,
    BalanceSheet1,
    Nominal1,
    APAR1,
    SentEmailWrapper,
  },
  setup(props) {
    const selected = ref(props.id);
    let ids = selected;
    let orgdata = ref("");
    let count = ref("");
    let client_name = ref("");
    const { state, dispatch } = useStore();
    var selectrange = ref(null);
    var loading = ref(true);
    var refresh_btn = ref(false);

    onMounted(() => {
      ids.value = localStorage.getItem("org");
      getoverview(ids.value);
    });

    const getoverview = (ids,refresh = 0) => {
      
      loading.value = true;
            
      let user = JSON.parse(localStorage.getItem("access_token"));
      if (user) {
        dispatch("getoverview", {ids,refresh}).then(
          (response) => {
            //console.log(response.data);
            count.value = response.data;
            orgdata.value = response.data.orgdata;
            localStorage.setItem("syncdata", JSON.stringify(orgdata.value));
            refresh_btn.value = false;
          },
          (error) => {
            Notification["error"]({
              message: "error!",
              description: "Server Error.",
            });
            refresh_btn.value = false;
          }
        );
      }
      loading.value = false;
      // refresh_btn.value = false;
    };
    const exportChecklist = () => {
      let api_data = [];
      api_data['tenent_id'] = localStorage.getItem("org");
        dispatch("exportVatCheckList", api_data).then(
          (response) => {
            //Notification["message"]({
            //  message: "VAT Checklist Exported Succesfully!",
            //  description: "Exported.",
            //});
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'VAT Checklist.xlsx'); // Set file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          },
          (error) => {
            Notification["error"]({
              message: "error!",
              description: "Server Error.",
            });
            refresh_btn.value = false;
          }
        );
      
      };
    const DateSelect = (date, dateString) => {
      selectrange.value = dateString;
    };

    const xerosync = () => {
     //console.log(selectrange);
      var range = selectrange.value.toString().split(",");
      let startdate = new Date(range[0]);
      let enddate = new Date(range[1]);
      startdate = startdate.toLocaleDateString("en-CA");
      enddate = enddate.toLocaleDateString("en-CA");
      var rangeobj = [];
      rangeobj.push(startdate);
      rangeobj.push(enddate);
      dispatch("reposync", rangeobj).then(
        (response) => {
          //ids.value = localStorage.getItem("org");
          //getoverview(ids.value);
          Notification["success"]({
              message: "Child date Range has been Updated",
          });
          location.reload()
        },
        (error) => {
        Notification["error"]({
              message: "Error!",
              description: error.data.msg
          });
        }
      );
    };

    const refresh = () => {
      ids.value = localStorage.getItem("org");
      refresh_btn.value = true;
      getoverview(ids.value,1);
      // alert('refresh');
    }

    const exportToCSV = () => {
      const samplejson2 = [
        { Name: "Unreconciled Bank Transactions" , Count:count.value.unreconciled_bank_trans},
        { Name: "Capitalisation without Invoice" , Count:count.value.capitalisation_without_invoice},
        { Name: "Wrong Values in BS" , Count:count.value.bswrongvalues_count},
        { Name: "Unused Chart of Accounts" , Count:count.value.unused_chart_accounts},
        { Name: "Repeating Sales Invoice" , Count:count.value.repeating_sales_invoice},
        { Name: "Repeating Purchase Invoice" , Count:count.value.repeating_purchase_invoice},
        { Name: "Inconsistent VAT Codes" , Count:count.value.inconsistent_vat},
        { Name: "Inconsistent Nominal Codes" , Count:count.value.inconsistent_nominal},
        { Name: "Inconsistent Nominal Codes with VAT Codes" , Count:count.value.inconsistent_Nominal_with_vat},
        { Name: "NO VAT/ZERO RATED /EXEMPT VAT" , Count:count.value.incorrect_vat},
        { Name: "Manual Journals with VAT" , Count:count.value.manual_journals_vat},
        { Name: "New Contact Name" , Count:count.value.incorrect_contact},
        { Name: "Unallocated Payments" , Count:count.value.unallocated_Payments},
        { Name: "Partial Paid Bills" , Count:count.value.partial_payments},
        { Name: "Partial Paid Invoice" , Count:count.value.receipts_allocation},
        { Name: "PI Invoices in Drafts" , Count:count.value.pi_invoices_in_drafts},
        { Name: "Suppliers with Debit Balances" , Count:count.value.suppliers_with_debit_balances},
        { Name: "SI > 90 Days" , Count:count.value.pi_90_days},
        { Name: "Invoices Matched for Later Days" , Count:count.value.wrong_mismatch},
        { Name: "Unallocated Receipts" , Count:count.value.unallocated_receipts},
        { Name: "SI in Drafts" , Count:count.value.si_in_drafts},
        { Name: "PI > 90 days" , Count:count.value.si_90_days},
        { Name: "Payments expensed out but invoices are outstanding" , Count:count.value.payment_expensed},
      ];

      const fileName =
        orgdata.value.client_name + "Overview" + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(samplejson2);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    return {
      ids,
      orgdata,
      client_name,
      count,
      xerosync,
      selectrange,
      DateSelect,
      loading,
      exportToCSV,
      refresh,
      refresh_btn,
      exportChecklist,
    };
  },
});
</script>
<style scoped>
.listbody {
  padding: 5px !important;
}
</style>
 
